import { render, staticRenderFns } from "./404.vue?vue&type=template&id=1e78093c&"
import script from "./404.vue?vue&type=script&lang=js&"
export * from "./404.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuSpacer: require('/codebuild/output/src930045601/src/onvocado-landing/components/global/MenuSpacer.vue').default,PageNotFound: require('/codebuild/output/src930045601/src/onvocado-landing/components/global/PageNotFound.vue').default,Footer: require('/codebuild/output/src930045601/src/onvocado-landing/components/global/Footer.vue').default,PreviewModeWrapper: require('/codebuild/output/src930045601/src/onvocado-landing/components/global/PreviewModeWrapper.vue').default})
